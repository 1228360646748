import '../Styles/JustoNecesitas.css'
import beneficiosexclusivos from "../imagenes/JustoNecesitas/BeneficiosExclusivos.svg"
import planes from "../imagenes/JustoNecesitas/Planes.svg" 
import contrataonline from "../imagenes/JustoNecesitas/ContrataONLINE.svg"
import coberturalab from "../imagenes/JustoNecesitas/CoberturaLab.svg" 
import Aos from "aos"
import "aos/dist/aos.css"
import React from 'react'


const JustoNecesitas = () => {
  React.useEffect(() => {
    Aos.init({duration: 2000});
  
  }, []);

    return (
      
      <div data-aos="fade-up" id="jn">
        <div className="mt-4">
          <h1 className="titulos">SEGUROS MEDS</h1>
          <h6 className="subtitulos">Alivian el otro dolor, el de pagar</h6>
          <br />
          <section className="container">
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-4 mb-4 text-center">
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div>
                    <img src={beneficiosexclusivos} id="jnimg1" className="img-fluid" alt="beneficios exclusivos" />
                  </div>
                  <div>
                    <h5 className="jntitulos">Beneficios exclusivos en Clínica MEDS y sus Centros Médicos.</h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div>
                    <img
                      src={planes}
                      id="jnimg2"
                      className="img-fluid" alt="Planes Fonasa Isapre"
                    />
                  </div>
                  <div>
                    <h5 className="jntitulos">Planes para Fonasa e Isapre.</h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div id="jndimg2">
                    <img
                      src={contrataonline}
                      id="jnimg3"
                      className="img-fluid" alt=""
                    />
                  </div>
                  <div>
                    <h5 className="jntitulos">Contrata 100% online.</h5>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card mb-4 shadow-ms h-100 jncard">
                  <div id="jndimg3">
                    <img src={coberturalab} id="jnimg4" className="img-fluid " alt="" />
                  </div>
                  <div>
                    <h5 className="jntitulos">Cobertura en prestaciones de Laboratorio y Entrenamiento.</h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <div className="text-center">
          <a className="btn btn-lg btn-primary animate__animated animate__bounce plboton" href="#sr">Ver planes</a>
        </div> */}
        <span>&nbsp;</span>
        </div>
        <span>&nbsp;</span>
      </div>      
    );
}

export default JustoNecesitas
