import img1 from "../imagenes/ComoFunciona/cf_corazon.svg"
import img2 from "../imagenes/ComoFunciona/cf_GastosCLINICA.svg"
import img3 from "../imagenes/ComoFunciona/cf_SistemaPAGO.svg"
import img4 from "../imagenes/ComoFunciona/cf_COBERTURA.svg"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

function ApiComoFuncionaHospitalizado(props) {
  const dispatch = useDispatch();
  const insuranceUF = useSelector((state) => state.InsuranceUF);

  useEffect(() => {
    dispatch({
      type: "INSURANCE_UF_REQUEST",
    });
  }, [dispatch]);

  return (
    <div id="complementario" className="container tab-pane fade">
                <br />
                <div
                  className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                  id="cfcol"
                >
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                      <div>
                        <img src={img1} className="img-fluid" id="cfimg3" alt="" />
                      </div>
                      <div className="card-body ">
                        <p>
                        Notas una mancha en tu brazo y te diriges a Clínica MEDS, en donde te diagnostican cáncer a la piel.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                      <div>
                        <img src={img2} className="img-fluid" id="cfimg2" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        El total de los gastos en la clínica es de $5.250.000 incluyendo honorarios médicos, insumos, hospitalización, exámenes y tratamientos.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                      <div>
                        <img src={img3} className="img-fluid" id="cfimg3" alt=""/>
                      </div>
                      <div className="card-body">
                      <p>
                        Si tu Sistema de Salud Previsional cubre $2.205.000 (42%), queda una diferencia de $3.045.000 por pagar.<br />
                        <br />                        
                        {/* No tendrás que pagar deducible hasta antes de los 60 años, luego deberás pagar UF 60 ($2.107.336) y se cubrirán al 100% los $937.664 restantes. */}
                        No tendrás que pagar deducible hasta antes de los 60 años, luego deberás pagar UF 60 ({!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(insuranceUF.data.value*60).toLocaleString("es-CL")}`}</span>)}) 
                        y se cubrirán al 100% los {!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(3045000 - (insuranceUF.data.value*60)).toLocaleString("es-CL")}`}</span>)} restantes.
                      </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                      <div>
                        <img src={img4} className="img-fluid" id="cfimg1" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                          Tendrás cobertura durante tres años por el mismo diagnóstico, desde el primer día en que se diagnostique la enfermedad.<br />
                          <br /> 
                          Además contarás con una indemnización de UF 50 por el primer diagnóstico de cáncer.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  );
}
export default ApiComoFuncionaHospitalizado;