import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

function ApiComoFunciona(props) {
  const dispatch = useDispatch();
  const insuranceUF = useSelector((state) => state.InsuranceUF);

  useEffect(() => {
    dispatch({
      type: "INSURANCE_UF_REQUEST",
    });
  }, [dispatch]);

  return (
    <div>
      Si tu Sistema de Salud Previsional cubre $4.125.000 (55%), queda una diferencia de $3.375.000 por pagar.<br /><br />
      Deberás pagar el deducible de tu plan y luego tendrás cubierto hasta el 100% del monto restante. Si tu deducible es de UF 30, deberás pagar {!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(insuranceUF.data.value*30).toLocaleString("es-CL")}`}</span>)}
      &nbsp;y se cubrirán los {!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(3375000 - (insuranceUF.data.value*30)).toLocaleString("es-CL")}`}</span>)} restantes.
    </div>    
  );
}
export default ApiComoFunciona;