import descuento from "../imagenes/TodosnosPreguntan/Descuento20.svg";
import exclusiones from "../files/Exclusiones.pdf";

const DEFAULT_FAQS_TO_SHOW = 2;

const FAQs = ({ data, index, watchAllFAQs}) => {
  const {
    id,
    nombre,
    descripcion
  } = data;

  if (index > DEFAULT_FAQS_TO_SHOW && !watchAllFAQs) {
    return null;
  }

  let DescriptionFAQ;

  if (id === 28) {
    const description_left_bold = descripcion.split("==")[0];
    const description_left = descripcion.split("==")[1];
    const description_right = descripcion.split("==")[2];

    DescriptionFAQ = <div className="row">
                      <div className="col-3 col-sm-4 col-md-2 align-self-center"><img src={descuento} alt="descuento" className="tnpimgdescuento"/></div>
                      <div className="col-9 col-sm-8 col-md-5">
                        <strong>{description_left_bold}</strong>
                        <br />{description_left}
                      </div>
                      <div className="col-sm-12 col-md-5 move-up-40">{description_right}</div>
                    </div>;
  } else if (id === 29) {
    // DescriptionFAQ = <iframe src={exclusiones} width="100%" height="800" title="pdf"></iframe>;
    let html = descripcion
    DescriptionFAQ = <div dangerouslySetInnerHTML={{ __html: html }} />;
  } else {
    DescriptionFAQ = descripcion;
  }


  return (
    <div className="accordion-item border-0" key={"TNP" + id}>
        <h2 className="accordion-header" id={id.toString()}>
            <button id={"FAQ" + id.toString()} className="accordion-button btn btn-block collapsed tnpacc" data-toggle="collapse" data-target={'#l'+id.toString()} aria-expanded="false" aria-controls={id.toString()}>
            <span className="tnpnombre">{nombre}</span>
            </button>
        </h2>
        <div id={"l"+id.toString()} className="accordion-collapse collapse" aria-labelledby={id.toString()}>
            <div className="accordion-body" style={{whiteSpace: "pre-line"}}>
              {DescriptionFAQ}              
            </div>
        </div>
    </div>
  );   
};
export default FAQs;