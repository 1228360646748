import img1 from "../imagenes/ComoFunciona/cf_corazon.svg"
import img2 from "../imagenes/ComoFunciona/cf_GastosCLINICA.svg"
import img3 from "../imagenes/ComoFunciona/cf_SistemaPAGO.svg"
import img4 from "../imagenes/ComoFunciona/cf_COBERTURA.svg"
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

function ApiComoFuncionaExamenes(props) {
  const dispatch = useDispatch();
  const insuranceUF = useSelector((state) => state.InsuranceUF);

  useEffect(() => {
    dispatch({
      type: "INSURANCE_UF_REQUEST",
    });
  }, [dispatch]);

  return (<div id="examenes" className="container tab-pane fade">
                <br />
                <div
                  className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                  id="cfcol"
                >
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                      <div>
                        <img src={img1} className="img-fluid" id="cfimg3" alt="" />
                      </div>
                      <div className="card-body ">
                        <p>
                        Debes acudir a una consulta médica o realizarte un examen en Clínica MEDS.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                      <div>
                        <img src={img2} className="img-fluid" id="cfimg2" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        El total a pagar por la consulta médica y los exámenes asociados es de $150.000. Si tu Sistema Previsional Isapre o Fonasa te cubre un 35% ($52.500), quedarán $97.500 por pagar.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                      <div>
                        <img src={img3} className="img-fluid" id="cfimg3" alt=""/>
                      </div>
                      <div className="card-body">
                      <p>
                      Según tu plan contratado podrás tener hasta un 60% de cobertura ($58.500). <br /><br />
                      Una vez consumido el deducible de UF, tendrás que pagar sólo {!insuranceUF.fetching && insuranceUF.data && ( <span>{`$${Math.round(58500 - (insuranceUF.data.value * 1)).toLocaleString("es-CL")}`}</span>)}.
                      </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                      <div>
                        <img src={img4} className="img-fluid" id="cfimg1" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        Podrás tener cobertura por 1 año por el mismo diagnóstico, siempre y cuando la póliza se mantenga vigente y los pagos de la prima al día.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>    
  );
}
export default ApiComoFuncionaExamenes;
