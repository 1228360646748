import "../Styles/Planes.css";
import medalla from "../imagenes/SeccionRenata/medalla.svg";
import React from "react";
import { IconCheckbox } from "react-icon-checkbox";
import { useDispatch } from "react-redux";

const MAX_SELECTABLE = 3;
const DEFAULT_CARDS_TO_SHOW = 3;

const Planes = ({ data, click, index, watchAll, selectedList, numPeople, maxAge, amounts}) => {
  const dispatch = useDispatch();
  const {
    idSeguro,
    nombreSeguro,
    cobertura,
    capitalAsegurado,
    deducible,
    esDestacado,
    valorSeguro,
    valorPrima,
    url,
  } = data;

  const [value, setValue] = React.useState(false);

  const onChangeValue = () => {
    if (selectedList.length <= MAX_SELECTABLE) {
      click(idSeguro, !value);
      setValue(!value);
    } else {
      if (value) {
        click(idSeguro, !value);
        setValue(!value);
      }
    }
  };

  // mejoras-marcaje-GA
  const planClassName = nombreSeguro.replace(/\s+/g, '-');

  if (index > DEFAULT_CARDS_TO_SHOW && !watchAll) {
    return null;
  }

  return (
    <div className="row mb-3 text-center" key={idSeguro}>
      <div className="col plcol">
        <div className="card mb-4 rounded-bottom-left-1 rounded-bottom-right-1 rounded-top-right-1 rounded-top-left-1 shadow-sm h-100 ">
          <div className="card-header text-white  rounded-top-left-1 rounded-top-right-1   " style={{ height: 70 , backgroundColor: "#3268C0", borderTopLeftRadius: "0.95rem", borderTopRightRadius: "0.95rem" }}  >
          
          {esDestacado && (
                <img src={medalla} className="plmedalla img-fluid mt-0 " alt="medalla" />
                )}
            <div style={{marginTop:"0.5rem", whiteSpace:"pre-line"}}>
              <strong>{nombreSeguro}</strong>
              </div>
          </div>
          <div className="card-body">
          <br/>
            <h1 className="card-title pricing-card-title">
              {`$${valorSeguro.toLocaleString("es-CL")}`}
              <strong>{`  UF ${valorPrima.toFixed(4)}/mes`}</strong>
            </h1>
            <p>
              <span className="text-muted fw-light pluf">Cobertura:</span>
            </p>
            <p style={{ minHeight: 80, marginTop: -13 }}>
              <span className="plparrafo">{cobertura}</span>
            </p>
            <button
              id={"detalle" + {idSeguro}}
              type="button"
              className={`w-60 btn btn-lg btn-primary mb-4 contrataboton ${planClassName}`}
              onClick={() => {
                dispatch({
                  type: "INSURANCE_COMPARATOR_REQUEST",
                  params: {
                    numPersonas: numPeople,
                    edadMaxima: maxAge,
                    vmMaximo: amounts[1],
                    strSeguros: idSeguro,
                  },
                });
              }}
            >Ver detalle</button>
            <br/>
            <a target="_blank"
              rel="noreferrer"
              href={url}
              className={`w-60 btn btn-lg btn-primary mb-4 plboton ${planClassName}`}
            >Contrata</a>
            {/* <div className="row" style={{ height: 80, alignItems: "flex-end" }}>
              <div>
                <IconCheckbox
                  checked={value}
                  checkedIcon={
                    <div className="card rounded-3 shadow-sm psdivcomparar">
                    <p className=" container pscomparar">Comparar<i
                      className="bi bi-plus-lg plicono plicono"></i></p>
                  </div>
                  }
                  uncheckedIcon={<div className="card rounded-3 shadow-sm pdivcomparar">
                  <p className="container pcomparar">Comparar<i 
                  className="bi bi-plus-lg plicono plicono">
                  </i></p>  
                  </div>}
                  onClick={onChangeValue}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Planes;
