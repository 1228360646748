import Table from "react-bootstrap/Table";
import "../Styles/Planes.css";
import ReactTooltip from 'react-tooltip'
import {useEffect} from 'react';

function Comparator({ data }) {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  return (
    <Table striped bordered responsive>
      <thead >
        <tr className="ctr">
          <th></th>
          {data.map(({ nombreSeguro}) => {
            return (
              <td>
                <center>
                  <div className="cnombreseguro">
                    <h5><strong>{nombreSeguro}</strong></h5>
                  </div>
                </center>
              </td>

            );
          })}
        </tr>
        <tr>
          <th>
            <div className="compasistencia"><h4 className="cpcobertura">Coberturas</h4></div>
          </th>
          {data.map(({ valorSeguro, valorPrima, url, cobertura, nombreSeguro}) => {
            const planClassName = nombreSeguro.replace(/\s+/g, '-');
            return (
              <td >
                <center >
                  <h2 className="cvalorseguro"><strong>{`$${valorSeguro.toLocaleString("es-CL")}`}</strong></h2>
                  <h6 className="cvalorprima">{`UF ${valorPrima}/mes`}</h6>
                  <a
                    target="_blank" rel="noreferrer"
                    href={url}
                    className={`w-60 btn btn-sm mb-3 ${planClassName}`}
                    style={{fontWeight:"bold", color:"white", backgroundColor:"#c8c8c8", borderColor:"#c8c8c8"}}
                  >
                    Contratar Plan
                  </a>
                  <p style={{ minHeight: 5 }}>
                  <span className="plparrafo2">{cobertura}</span>
                  </p>
                  <br></br>
                </center>
              </td>

            );
          })}
        </tr>
      </thead>
      <tbody>
        {data[0].comparacion.map(({orden,caracteristica}, index) => {
          function ToolTipMaternidad(props) {
            const Etiqueta = props.Etiqueta;
            if (Etiqueta === "Maternidad") {
              return (
                  <span className="ms-2">
                     <i className="bi bi-info-circle icoinfo" data-tip data-for={Etiqueta}></i>
                     <ReactTooltip
                      id={Etiqueta}
                      type="info"
                      effect="solid"
                      backgroundColor="#3268C0"
                    >
                      La fecha probable de inicio del embarazo debe ser<br /> posterior a la fecha de inicio<br /> de la vigencia de esta<br /> cobertura para el asegurado<br /> que se aplica.
                    </ReactTooltip>
                  </span>
                  );
            }
            return "";
          }
          return (
            <tr>
              <td>
                <div style={{textAlign:"center"}}>
                  <strong>{caracteristica}</strong>
                  <ToolTipMaternidad Etiqueta={caracteristica} />
                </div>
              </td>
              {data.map(({ comparacion }) => {
                                function CheckedCompare(props) {
                                  const IsCheck = props.IsCheck;
                                  const Check = props.Check;
                                  if (IsCheck && Check) {  return <div className="cdivicon"><i size={3} className="bi bi-check-circle coicono" style={{ color: "#a4ce4e"}}></i></div>; }
                                  if (IsCheck && !Check) {  return <i className="bi bi-check-circle coicono"></i>; }
                                  return "";
                                }
                          function EmptyCompare(props) {
                            const Informacion = props.Informacion;
                            const IsCheck = props.IsCheck;
                            if (!IsCheck && ((Informacion.trim()).length === 0)  ){  return <i className="bi bi bi-x-circle coicono"></i>; }
                            return "";
                          }                      
                return (
                  <td style={{textAlign:"center"}}>
                    <CheckedCompare Caracteristica={comparacion[index].caracteristica} IsCheck={comparacion[index].isCheck} Check={comparacion[index].check}/>
                    <p>{comparacion[index].informacion}</p>
                    <EmptyCompare Informacion={comparacion[index].informacion} IsCheck={comparacion[index].isCheck} Check={comparacion[index].check}/>
                  </td>
                );
              })}              
            </tr>
          );
        })}
      </tbody>
    </Table>
  );

}
export default Comparator;
