import "../Styles/Footer.css";
import logoIbarra from "../imagenes/Home/IBARRA-pie.svg";
import logoMEDS from "../imagenes/Home/MEDs-pie.svg";
import logoM from "../imagenes/Home/METlife-pie.svg";
import ApiUF from "./ApiUF";

const Footer = () => {
  return (
    <div id="ft">
      <div className="container">
        <br/>
        <div className="fdivlogos">
          <img src={logoIbarra} className="flogo1" alt="Ibarra" />
          <img src={logoMEDS} className="flogo3" alt="Clinica MEDS" />
          <img src={logoM} className="flogo2" alt="MetLife" />
        </div>
        <div id="ftlegal" className="fdivtextolegal  ">
          <br></br>
          <br></br>
          <p className="text-center cfparrafo"><b><ApiUF Tipo="2" /></b></p>
          <p className="text-center cfparrafo">
            <br></br>
            La presente información sólo representa un resumen de las coberturas, condiciones y limitaciones del Seguro. 
            El detalle de las condiciones, términos y exclusiones se encuentra en las Condiciones Generales POL 3 2022 0183 
            para la cobertura de Alto Costo, POL 3 2020 0090 y POL 3 2013 0146 para la cobertura e Indemnización Oncológica, 
            POL 3 2022 0168 para la cobertura Complementaria y POL 3 2023 0372 para la cobertura de Renta Diaria por Hospitalización 
            por Accidente e Indemnización por Fractura de Huesos. Intermediado por IBARRA Y CIA. LTDA CORREDORES DE SEGURO. 
            Los beneficios entregados por la clínica no son acumulables y no constituyen cobertura de seguro, siendo responsabilidad 
            su otorgamiento del respectivo prestador, sin responsabilidad alguna para MetLife Chile Seguros de Vida S.A.
          </p>
        </div>
      </div>
      <input id="version" name="version" type="hidden" value={"V" + process.env.REACT_APP_VERSION} />
    </div>
  );
};
export default Footer;
