import React from "react";
import "../Styles/SeccionRenata.css";
import "aos/dist/aos.css";
import Aos from "aos";

const CondicionesSeguros = () => {
    React.useEffect(() => {
        Aos.init({duration: 2000});
          }, []);
  return (
    <>
      <div id="cs">
        <section data-aos="fade-up">
          <div className="container">
            <ul
              className="nav nav-tabs srul"
              role="tablist"
            >
              <li className="nav-item card">
                <a
                  className="nav-link active srcardtitulo"
                  data-toggle="tab"
                  href="#altocosto"
                  id="bccardtitulo1"
                >
                  <div>
                    <h5 className="srtitulos">Condiciones de Alta Protección</h5>
                  </div>
                </a>
              </li>
              <li className="nav-item card">
                <a
                  className="nav-link srcardtitulo"
                  data-toggle="tab"
                  href="#hospitalarioexamenes"
                  id="bccardtitulo2"
                >
                  <div>
                    <h5 className="srtitulos">
                    Condiciones de Oncológico
                    </h5>
                  </div>
                </a>
              </li>
              <li className="nav-item card">
                <a
                  className="nav-link srcardtitulo"
                  data-toggle="tab"
                  href="#bcaccidentes"
                  id="bccardtitulo3"
                >
                  <div>
                    <h5 className="srtitulos">Condiciones de Complementario</h5>
                  </div>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div id="altocosto" className="container tab-pane active">
                <div className="bcparrafo col card px-3">
                  <div className="bcparrafo3">
                    <ul>
                      <li>Debes tener una afiliación vigente con Sistema Previsional de Salud Isapre o Fonasa.</li>
                      <li>Puedes contratar desde los 18 años hasta antes de cumplir los 75 años.</li>
                      <li>Para incorporar a tu pareja/cónyuge/conviviente civil como carga debe ser mayor a 18 años y menor a 75 años.</li>
                      <li>Para incorporar como carga a tus hijos, deben tener al menos 14 días de vida y ser menores 23 años.</li>
                      <li>Tanto tú como tu pareja/cónyuge/conviviente civil pueden permanecer de forma vitalicia, siempre y cuando la póliza se encuentre vigente y el pago de las primas se encuentre al día. </li>
                      <li>No cubre preexistencias.</li>
                      <li>Para avanzar la contratación debes completar una Declaración Personal de Salud, en donde aplican exclusiones que
                    puedes revisar en el pie de página.</li>
                    </ul>                  
                  </div>
                </div>
              </div>
              <div
                id="hospitalarioexamenes"
                className="container tab-pane fade  "
              >
                <div className="bcparrafo col card px-3">
                  <div className="bcparrafo3">
                    <ul>
                      <li>Debes tener una afiliación vigente con Sistema Previsional de Salud Isapre o Fonasa.</li>
                      <li>Puedes contratar desde los 18 años hasta antes de cumplir los 70 años.</li>
                      <li>Para incorporar a tu pareja/cónyuge/conviviente civil como carga debe ser mayor a 18
                        años y menor a 70 años.</li>
                      <li>Para incorporar como carga a tus hijos, deben tener al menos 14 días de vida y ser menores 23 años.</li>
                      <li>Tanto tú como tu pareja/cónyuge/conviviente civil pueden permanecer de forma vitalicia, siempre y cuando 
                        la póliza se encuentre vigente y el pago de las primas se encuentre al día.</li>
                      <li>No cubre preexistencias.</li>
                      <li>Para avanzar la contratación debes completar una Declaración Personal de Salud, en donde aplican exclusiones que
                        puedes revisar en el pie de página.</li>
                    </ul>                    
                  </div>
                </div>
              </div>
              <div id="bcaccidentes" className="container tab-pane fade  ">
                <div className="bcparrafo col card px-3">
                  <div className="bcparrafo3">
                    <ul>
                      <li>Debes tener una afiliación vigente con Sistema Previsional de Salud Isapre o Fonasa.</li>
                      <li>Puedes contratar desde los 18 años hasta antes de cumplir los 60 años.</li>
                      <li>Para incorporar a tu pareja/cónyuge/conviviente civil como carga debe ser mayor a 18
                            años y menor a 60 años.</li>
                      <li>Para incorporar como carga a tus hijos, deben tener al menos 14 días de vida y ser menores 23 años.</li>
                      <li>Tanto tú como tu pareja/cónyuge/conviviente civil pueden permanecer hasta antes de cumplir los 65 años, siempre y cuando 
                            la póliza se encuentre vigente y el pago de las primas se encuentre al día.</li>
                      <li>No cubre preexistencias.</li>
                      <li>Para avanzar la contratación debes completar una Declaración Personal de Salud, en donde aplican exclusiones que
                            puedes revisar en el pie de página.</li>
                    </ul>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default CondicionesSeguros;
