import "../Styles/BeneficiosCondiciones.css";
import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";

const BeneficiosCondiciones = () => {
  React.useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <div data-aos="flip-left" id="bc">
      <div className="mt-4">        
        <h1 className="titulos">BENEFICIOS SEGUROS CLÍNICA MEDS</h1>
      </div>
      <br />
      <div id="bccard">
        <div className="container">
          <br />
          <br />
          <div className="bccontainer1">
            <div className="bcparrafos bcparrafos-small">
              <div className="bctexto">
                <h5>
                20% de descuento en el copago de consultas médicas, exámenes de laboratorio e imágenes (*).
                </h5>
              </div>
            </div>
            <div className="bcparrafos">
              <div className="bctexto">
                <h5>
                20% de descuento en masoterapia, análisis de pisada y plantillas, terapia ocupacional, 
                evaluación check up deportivo, MEDS Healthy y entrenamiento en cámara de hipoxia.
                </h5>
              </div>
            </div>
            <div className="bcparrafos bcparrafos-small">
              <div className="bctexto">
                <h5>
                15% de descuento <br />Acupuntura.
                </h5>
              </div>
            </div>
          </div>
          <br />
          <br />
          <p className="container cfparrafo text-center">
            *Beneficios exclusivos de nuestros seguros a través de IMED,{" "}
            <b>sin trámites.</b>          
          </p>
          <br />          
        </div>
      </div>      
    </div>
  );
};

export default BeneficiosCondiciones;
